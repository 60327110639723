<script>
	import { LobbyScreen } from "da-components/v0";
	import { SPLASH_SCREEN1 } from "../constants/screenConstants";
	import { dbGameSessionPage } from "../services/database";
	import { usersStore, myUserStore, hostId as hostIdStore } from "../services/store";
	import { CLICK } from "../constants/soundConstants";
	import { playSound } from "../services/sounds";
	import { fade } from "svelte/transition";
	import Header from "../components/Header.svelte";

	let message = "";

	$: hostId = $hostIdStore;

	$: users = Object.values($usersStore);
	$: onlineUsers = users.filter(user => user.isOnline);
	$: offlineUsers = users.filter(user => !user.isOnline);

	function handleStartGame() {
		if (onlineUsers.length >= 2) {
			dbGameSessionPage.set(SPLASH_SCREEN1);
		} else {
			message = "Atleast 2 players required to start the game!";
			setTimeout(() => {
				message = "";
			}, 2000);
		}
	}
</script>

<Header />
<div class="container">
	<LobbyScreen
		isHost={hostId == $myUserStore?.id}
		{onlineUsers}
		{offlineUsers}
		{handleStartGame}
		{hostId}
		hostName={$usersStore[hostId]?.userName}
		onMouseDown={() => playSound(CLICK)}
		bottomText="GREAT FOR ENGAGEMENT WITH SMALL GROUPS. GET ARTSY & CREATIVE WITH YOUR TEAM"
	/>
	{#if message}
		<h1 class="highlight" in:fade out:fade>{message}</h1>
	{/if}
</div>

<style>
	.highlight {
		margin-top: 5px;
		position: fixed;
		text-align: center;
		left: 50%;
		transform: translateX(-50%);
	}
	.container {
		position: relative;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	}
</style>
