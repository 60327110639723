<script>
	import { onMount } from "svelte";
	import { ExitModalScreen } from "da-components/v0";

	import {
		SPLASH_SCREEN,
		LOBBY_SCREEN,
		TIMER_SCREEN,
		CHOOSE_WORD_SCREEN,
		TURN_SKIPPED_SCREEN,
		CANVAS_SCREEN,
		END_PROMPT_SCREEN,
		LEADERBOARD_SCREEN,
		SPLASH_SCREEN1,
	} from "../constants/screenConstants";
	import {
		dbChats,
		dbUsers,
		dbGameSessionPage,
		listenOnFirebaseKey,
		dbGameSessionRound,
		dbGameSessionRoundValue,
		dbWinner,
		dbTheme,
		dbHost,
		user,
	} from "../services/database";

	import {
		artist,
		usersStore,
		chat,
		myUserStore,
		winner,
		didIGuess,
		currentTheme,
		hostId as hostIdStore,
		showModal,
	} from "../services/store";
	import { SplashScreen } from "da-components/v0";
	import LobbyScreen from "./LobbyScreen.svelte";
	import TimerScreen from "./TimerScreen.svelte";
	import ChooseWordScreen from "./ChooseWordScreen.svelte";
	import TurnSkippedScreen from "./TurnSkippedScreen.svelte";
	import GameScreen from "./GameScreen.svelte";
	import EndPromptScreen from "./EndPromptScreen.svelte";
	import LeaderboardScreen from "./LeaderboardScreen.svelte";
	import { playSound } from "../services/sounds";
	import {
		BGMUSIC_START,
		BGMUSIC_STOP,
		COUNTDOWN_START,
		SKIPPED,
		TIMER_TO_CANVAS_START,
	} from "../constants/soundConstants";
	import { endRound, getParams } from "../services/utils";
	import { info } from "../services/notifier";

	let screen = SPLASH_SCREEN;
	let hostId;
	let theme;
	let isHost = getParams("isHost") === "true";
	$: users = Object.values($usersStore);
	// ALl listenOnFirebaseKey calls
	onMount(() => {
		listenOnFirebaseKey(dbUsers, val => {
			usersStore.set(val);
		});
		listenOnFirebaseKey(dbGameSessionPage, val => {
			screen = val;
		});
		listenOnFirebaseKey(dbGameSessionRoundValue, _ => {
			if (_) {
				winner.set();
				didIGuess.set(false);
				listenOnFirebaseKey(dbGameSessionRound().child("artist"), artistId => {
					if (artistId) artist.set($usersStore[artistId]);
				});
				listenOnFirebaseKey(dbWinner(), winnerId => {
					winner.set($usersStore[winnerId]);
					didIGuess.set(winner && $myUserStore?.id == $winner?.id);
				});
			}
		});
		listenOnFirebaseKey(dbHost, val => {
			if (hostId) {
				const oldHostName = users.find(user => user.id === hostId)?.userName;
				const newHostName = users.find(user => user.id === val)?.userName;
				let message = "";
				if (hostId === user.id) {
					message = `${oldHostName || "Old Host"} has left the game and you are the new host`;
				} else {
					if (newHostName) {
						message = `${oldHostName || "Old Host"} has left the game and new host is ${newHostName}`;
					} else {
						message = `${oldHostName || "Old Host"} has left the game and new host has been assigned`;
					}
				}
				info(message);
			}
			hostId = val;
		});
		dbChats.on("child_added", snap => {
			const message = snap.val();
			const messageTime = new Date(message.timestamp);
			const timediff = (Date.now() - messageTime) / 1000;
			if (timediff <= 5) {
				chat.set(message);
			}
		});
		return () => dbChats.off("child_added");
	});
	$: screen == TURN_SKIPPED_SCREEN && playSound(SKIPPED);
	$: screen == CANVAS_SCREEN && playSound(TIMER_TO_CANVAS_START);
	$: (screen == CANVAS_SCREEN || screen === LEADERBOARD_SCREEN) && playSound(BGMUSIC_STOP);
	$: (screen === SPLASH_SCREEN || screen === LOBBY_SCREEN) && playSound(BGMUSIC_START);
	$: if (screen == TIMER_SCREEN) {
		playSound(BGMUSIC_STOP);
		playSound(COUNTDOWN_START);
	}
	listenOnFirebaseKey(dbTheme(), value => {
		if (value) {
			theme = {
				themeName: value["name"],
				themeEmoji: value["icon"],
			};
			currentTheme.set(theme);
		}
	});

	if (isHost) {
		dbHost
			.get()
			.then(snap => {
				if (!snap.val()) {
					dbHost.set(user.id);
				}
			})
			.catch(err => console.log("call from here", err));
	}
	$: {
		if (user.id === hostId) {
			dbHost.onDisconnect().remove();
		}
	}
	$: hostId && hostIdStore.set(hostId);
	$: screen && showModal.set(false);
</script>

{#if $showModal === true}
	<div class="exitModalWrapper">
		<ExitModalScreen
			gameOrRound="round"
			onMouseDown={() => playSound("CLICK")}
			startNew={() => {
				endRound().then(() => showModal.set(false));
			}}
			goBack={() => {
				showModal.set(false);
			}}
		/>
	</div>
{/if}

{#if screen == SPLASH_SCREEN || screen == SPLASH_SCREEN1}
	<SplashScreen
		svgSrc="/svg/texture.svg"
		bgColor="#5454F1"
		logoSrc="/svg/logo.svg"
		subHeading="❤️Let’s scribbl together🤗"
	/>
{:else if screen == LOBBY_SCREEN}
	<LobbyScreen />
{:else if screen == TIMER_SCREEN}
	<TimerScreen />
{:else if screen == CHOOSE_WORD_SCREEN}
	<ChooseWordScreen />
{:else if screen == CANVAS_SCREEN}
	<GameScreen />
{:else if screen == TURN_SKIPPED_SCREEN}
	<TurnSkippedScreen />
{:else if screen == LEADERBOARD_SCREEN}
	<LeaderboardScreen />
{:else if screen == END_PROMPT_SCREEN}
	<EndPromptScreen />
{:else}
	<span> Wrong Screen </span>
{/if}

<style>
	.exitModalWrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 10000000;
	}
</style>
