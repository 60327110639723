import firebase from "firebase/app";
import "firebase/database";
import { getGameSessionId, getParams } from "./utils";
import { LOBBY_SCREEN, SPLASH_SCREEN, GAME_SCREEN } from "../constants/screenConstants";
const firebaseConfig = {
	apiKey: process.env.API_KEY,
	authDomain: process.env.AUTH_DOMAIN,
	databaseURL: process.env.DATABASE_URL,
	projectId: process.env.PROJECT_ID,
	storageBucket: process.env.STORAGE_BUCKET,
	messagingSenderId: process.env.MESSAGING_SENDER_ID,
	appId: process.env.APP_ID,
};

firebase.initializeApp(firebaseConfig);

if (process.env.EMULATE) {
	const firebaseEmulators = {
		database: {
			host: "localhost",
			port: 9000,
		},
		functions: {
			host: "localhost",
			port: 5001,
		},
	};
	console.log("Automatically connecting Firebase SDKs to running emulators:");
	Object.keys(firebaseEmulators).forEach(function (key) {
		console.log("\t" + key + ": http://" + firebaseEmulators[key].host + ":" + firebaseEmulators[key].port);
	});
	if (firebaseEmulators.database && typeof firebase.database === "function") {
		firebase.database().useEmulator(firebaseEmulators.database.host, firebaseEmulators.database.port);
	}
	if (firebaseEmulators.firestore && typeof firebase.firestore === "function") {
		firebase.firestore().useEmulator(firebaseEmulators.firestore.host, firebaseEmulators.firestore.port);
	}
	if (firebaseEmulators.functions && typeof firebase.functions === "function") {
		firebase.functions().useEmulator(firebaseEmulators.functions.host, firebaseEmulators.functions.port);
	}
	if (firebaseEmulators.auth && typeof firebase.auth === "function") {
		firebase.auth().useEmulator("http://" + firebaseEmulators.auth.host + ":" + firebaseEmulators.auth.port);
	}
} else {
	console.log(
		"To automatically connect the Firebase SDKs to running emulators, replace '/__/firebase/init.js' with '/__/firebase/init.js?useEmulator=true' in your index.html"
	);
}

let roundValue = 1;

export const dbRoot = firebase.database().ref("scribbl");
export const dbGameSession = dbRoot.child(getGameSessionId());
export const dbUsers = dbGameSession.child("users");
export const dbUsersPlayed = dbGameSession.child("usersPlayed");
export const dbUser = dbUsers.child(getParams("userId"));
export const user = {
	id: getParams("userId"),
	userName: getParams("userName"),
	profilePicture: getParams("userProfilePicture"),
};

export const getRoundValue = () => {
	return roundValue;
};

export const dbHost = dbGameSession.child("host");
export const dbGameSessionRoundValue = dbGameSession.child("roundValue");
export const dbGameSessionRounds = dbGameSession.child("rounds");
export const dbGameSessionPage = dbGameSession.child("page");
export const dbGameScore = dbGameSession.child("score");
export const dbAllWords = dbGameSession.child("allWords");
export const dbThemeId = dbGameSession.child("themeId");
export const dbUsedWords = dbGameSession.child("usedWords");
export const dbGameSessionRound = () => dbGameSessionRounds.child(`${getRoundValue()}`);
export const dbLines = () => dbGameSessionRound().child("lines");
export const dbEncryptedWord = () => dbGameSessionRound().child("encryptWord");
export const dbWinner = () => dbGameSessionRound().child("WINNER");
export const dbGameState = () => dbGameSessionRound().child("STATE");
export const dbChats = dbGameSession.child("chats");
export const dbScoreRef = dbGameSession.child("score");
export const dbCountdownTimer = dbGameSession.child("countDownTimer");

export const dbTheme = () => dbGameSession.child("theme");

window?.DIVE_APP?.listenOnAppThemeChange(async theme => {
	console.log(theme, "listenOnAppThemeChange");
	await dbThemeId.set(theme.th_id);
	await dbTheme().set(theme);
});

export function listenOnFirebaseKey(firebaseKey, callback, notExitCallback) {
	Promise.resolve(roundTimeValuePromise).then(() => {
		firebaseKey.on("value", snap => {
			if (snap.exists()) {
				callback(snap.val());
			} else {
				notExitCallback && notExitCallback();
			}
		});
	});
}

const roundTimeValuePromise = new Promise((resolve, reject) => {
	dbGameSessionRoundValue.once("value", snap => {
		if (!snap.exists()) {
			roundValue = 1;
		} else {
			roundValue = snap.val();
		}
		resolve();
	});
});

dbGameSessionRoundValue.on("value", snap => {
	if (!snap.exists()) {
		dbGameSessionRoundValue.set(1);
		dbGameSessionPage.set(SPLASH_SCREEN);
		roundValue = 1;
		return;
	}
	roundValue = snap.val();
});

const amIHost = getParams("isHost") === "true";
const connectedRef = firebase.database().ref(".info/connected");
connectedRef.on("value", snap => {
	if (snap.val() === true) {
		dbUser.update({
			online: true,
		});
		dbUser.onDisconnect().update({
			online: firebase.database.ServerValue.TIMESTAMP,
		});
	}
});

dbUser.update(user);

export const queryParams = new URLSearchParams(window.location.search);
export const userId = queryParams.get("userId");
export const roomId = queryParams.get("roomId");
export const sessionId = queryParams.get("sessionId");
export const isHost = queryParams.get("isHost");
export const userName = queryParams.get("userName");
export const userProfilePicture = queryParams.get("userProfilePicture");

export const usersSequenseRef = firebase.database().ref(`/scribbl/${roomId}+${sessionId}/sequense/`);

export const usersGameDataRef = firebase.database().ref(`/scribbl/${roomId}+${sessionId}/gameData/`);

export const usersChatDataRef = firebase.database().ref(`/scribbl/${roomId}+${sessionId}/chats/`);

export const usersPointsDataRef = firebase.database().ref(`/scribbl/${roomId}+${sessionId}/linePoints/`);

export const timesUpDataRef = firebase.database().ref(`/scribbl/${roomId}+${sessionId}/timesUp/`);

export const userLeaderboardRef = firebase.database().ref(`/scribbl/${roomId}+${sessionId}/users/` + userId + "/score");

export default firebase;
