<script>
	import { LeaderboardScreen } from "da-components/v0";
	import { onMount } from "svelte";
	import { fade } from "svelte/transition";
	import Header from "../components/Header.svelte";
	import { CHOOSE_WORD_SCREEN } from "../constants/screenConstants";
	import { dbGameSessionPage, dbScoreRef, listenOnFirebaseKey } from "../services/database";
	import { hostId as hostIdStore, myUserStore, usersStore } from "../services/store";

	let scores,
		oneLineMessage,
		message = "";
	$: hostId = $hostIdStore;
	$: users = Object.values($usersStore);
	$: onlineUsers = users.filter(user => user.isOnline);

	const handleNewGame = async () => {
		if (onlineUsers.length >= 2) {
			await dbGameSessionPage.set(CHOOSE_WORD_SCREEN);
		} else {
			message = "Atleast 2 players required to start the game!";
			setTimeout(() => {
				message = "";
			}, 2000);
		}

		window?.DIVE_APP?.sendEvent({
			type: "playing_again",
			payload: {
				playingAgain: true,
			},
		});
	};

	onMount(() => {
		listenOnFirebaseKey(dbScoreRef, val => {
			scores = val;
		});
	});

	const formatDataForLeaderboard = scores => {
		if (!scores) return [];
		const mapScoreToUser = {};
		Object.keys(scores).forEach(
			userId => (mapScoreToUser[scores[userId]] = [...(mapScoreToUser[scores[userId]] || []), userId])
		);
		const sortedScores = [].sort.call(Object.keys(mapScoreToUser), (a, b) => (parseInt(a) > parseInt(b) ? -1 : 1));

		let position = 1;
		let sharedTop = false;
		let myPos = 4;
		const output = sortedScores.map(score => {
			if (position === 1 && mapScoreToUser[score].length > 1) sharedTop = true;
			return {
				position: position++,
				usersArray: mapScoreToUser[score].map(userId => {
					if (userId == $myUserStore?.id) myPos = position - 1;
					return {
						id: $usersStore[userId]?.id,
						name: $usersStore[userId]?.userName,
						src: $usersStore[userId]?.profilePicture,
						score,
					};
				}),
			};
		});
		if (myPos === 1 && !sharedTop) {
			oneLineMessage = "Wohhoo! You Won 🔥";
		} else if (myPos > 1 && !sharedTop) {
			oneLineMessage = "That’s a Clear Win 🎉";
		} else if (myPos === 1 && sharedTop) {
			oneLineMessage = "You Won & it’s a Tie! 🤝";
		} else if (sharedTop) {
			oneLineMessage = "Damn! It’s a Tie 🤝 Well Played";
		} else {
			oneLineMessage = "Let’s keep going 💪";
		}
		return output;
	};
</script>

{#if scores}
	<Header />
	<div class="container">
		<LeaderboardScreen
			userId={$myUserStore?.id}
			isHost={hostId == $myUserStore?.id}
			continueHandler={handleNewGame}
			hostName={$usersStore[hostId]?.userName}
			users={formatDataForLeaderboard(scores)}
			nextRoundOrPlayAgain="Next Round"
			{oneLineMessage}
		/>
		{#if message}
			<h1 class="highlight" in:fade out:fade>{message}</h1>
		{/if}
	</div>
{/if}

<style>
	.highlight {
		margin-top: 5px;
		position: fixed;
		text-align: center;
		/* bottom: 20px; */
		left: 50%;
		transform: translateX(-50%);
	}
	.container {
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>
