import { dbGameState } from "./database";

export const params = new URLSearchParams(window.location.search);
export const getParams = function (name) {
	return "" + params.get(name);
};
export const getGameSessionId = function () {
	return `${getParams("roomId")}+${getParams("sessionId")}`;
};
export function isEqual(array1, array2) {
	return JSON.stringify(array1) === JSON.stringify(array2);
}
export function changeToThemePage() {
	window?.DIVE_APP?.changeToAppThemePage?.();
}

export async function endRound() {
	changeToThemePage();
	await dbGameState().set("ENDED");
}
